<template>
	<div class="cs-image-slider cs-block" :class="styleClass">
		<div class="cs-block-base">
			<div class="slider-back">
				<div v-if="data.title || data.subTitle || data.text" class="container">
					<div class="header-content">
                        <h2 v-if="data.title" class="cs-title">{{ data.title }}</h2>
						<h3 class="cs-sub-title" v-if="data.subTitle">{{ data.subTitle }}</h3>
						<span class="cs-text" v-if="data.text" v-html="data.text"></span>
					</div>
				</div>
                <Slider
                    :data="imageSlides"
                    v-slot="item"
                    :slidesToShow="1"
                    :hasArrow="data.hasArrows"
                >
                    <CSButton :data="item.slide.url" class="slide-link">
                        <img :src="item.slide.image" class="slick-item" />
                        <div class="img-filler"/>
                        <div class="slide-content" v-if="item.slide.title || item.slide.text">
                            <h4 class="slide-title" v-if="item.slide.title">{{ item.slide.title }}</h4>
                            <span class="slide-text cs-text" v-if="item.slide.text" v-html="item.slide.text"/>
                        </div>
                    </CSButton>
                </Slider>
			</div>
		</div>
	</div>
</template>

<script>
import {computed} from '../libs/common-fn';

export default {
	name: "MainSlider",
	props: {
		data: {
			type: Object,
			default: () => {},
		},
	},
	components: {
		Slider: () => import("../blocks_components/Slider.vue"),
        CSButton: () => import("../components/CSButton.vue"),
	},
	data() {
		return {}
	},
	computed: computed('ImageSlider')
}
</script>

<style lang="scss" scoped>
@import "../styles/main.scss";
.cs-image-slider {
    &.cs-style-dark {
        .slide-content {
            background: $bg_dark !important;
            h4.slide-title {
                color: #fff !important;
            }
            span {
                color: #fff !important;
                ::v-deep * {
                    color: #fff !important;
                } 
            }
        }
    }
    ::v-deep .slider__arrow svg {
        fill: #fff !important;
    }
    .header-content {
        text-align: center;
        margin-bottom: 35px;
    }
        .slide-link {
            text-decoration: none;
            position: relative;
        }
        .slick-item, ::v-deep a {
            height: 750px;
            object-fit: cover;
            width: 100%;
            @media (max-width: 991px) {
                height: 450px;
            }
        }
        .img-filler {
            background-color: #000;
            position: absolute;
            width: 100%;
            opacity: .2;
            top: 0;
            left: 0;
            height: 100%;
            z-index: 0;
        }
        .slide-content {
            color: $secondary_text_color;
            position: absolute;
            bottom: 5%;
            width: 50%;
            left: 12%;
            background: $theme_color;
            padding: 30px;
            @media (max-width: 450px) {
                width: 80%;
                padding: 10px;
                bottom: 2%;
            }
            span.slide-text {
                @include text;

				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 5;
				-webkit-box-orient: vertical;
                
                ::v-deep {
                    *:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            h4.slide-title {
                @include small-title;
                margin-bottom: 10px;
            }
        }
    
    ::v-deep {
        .slider {
            overflow: hidden;
            .slider__arrow {
                z-index: 20;
                left: 5% !important;

                &.right {
                    right: 5% !important;
                    left: auto !important;
                }
            }
        }
        .slider__carousel .slider-item {
            padding: 0 !important;
        }
    }
}
</style>
